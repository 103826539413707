<template>
    <div class="session-wrapper">
        <div class="session-right text-xs-center" style="margin-left: unset !important;">
            <div class="session-table-cell">
                <div class="session-content">
                    <div class="text-center">
                        <img
                                :src="appLogo"
                                class="img-responsive mb-3"
                                width="150"
                                height="150"
                        />
                    </div>
                    <h2 class="mb-3">{{$t('message.forgotPassword')}}</h2>
                    <p class="fs-14">{{$t('message.enterYourEmailToSendYourPassword')}}.</p>
                    <v-form v-model="valid" class="mb-4">
                        <v-text-field
                                :label="$t('message.emailId')"
                                :rules="emailRules"
                                required
                                v-model="email"
                        ></v-text-field>
                        <v-btn large color="primary" block @click="" to="/session/forgot-password">Send Email</v-btn>
                    </v-form>
                    <div>
                        <router-link to="/session/login">{{$t('message.backToSignIn')}}</router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
    import SessionSliderWidget from "Components/Widgets/SessionSlider";
    import AppConfig from "Constants/AppConfig";

    export default {
        components: {
            SessionSliderWidget
        },
        data() {
            return {
                email: '',
                valid: false,
                emailRules: [
                    v => !!v || "E-mail is required",
                    v =>
                        /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
                        "E-mail must be valid"
                ],
                appLogo: AppConfig.appSessionLogo
            };
        }
    };
</script>
